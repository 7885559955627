export default [
    {
        id: 1,
        title: "Experience",
        description: "In my 8 years as a professional software engineer, I have had the opportunity to work with some great companies.",
        path: "/experience"
    },
    {
        id: 2,
        title: "Projects",
        description: "I like to constantly learn and grow with different projects. Here’s a comprehensive list of projects I’ve worked on. ",
        path: "/projects"
    },
    {
        id: 3,
        title: "Recommendation",
        description: "I am also very humbled by the words of some great professionals about my work. Here are my favorites.",
        path: "/recommendation"
    }
];
